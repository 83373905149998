import React from "react";
import {gql, useQuery} from "@apollo/client";
import {infoPublicSession} from "../api/apollo/queries/infoPublicSession";
import ErrorPage from "./ErrorPage";
import DataQueryCheck from "./DataQueryCheck";

const SessionTimeChecker: React.FC = () => {
    const GET_Session = gql(infoPublicSession);
    const {loading, error, data} = useQuery(GET_Session);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error && data?.infoForPublicDataSession.creationDate) {
        return <ErrorPage/>;
    }

    if (data) {
        return <DataQueryCheck repositoryID={data?.infoForPublicDataSession.teamRepository}/>;
    }

    return <ErrorPage/>
};

export default SessionTimeChecker;