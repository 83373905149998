import * as React from 'react';
import FilterSelect from "./FilterSelect";
import {useEffect, useState} from "react";

const FilterHeader: React.FC<{
    selectedScenario: string | null,
    setSelectedScenario: (scenarioTitle: string) => void;
    handleScenarioChange: any,
    availableScenarios: any,
}> = ({
          selectedScenario,
          setSelectedScenario,
          handleScenarioChange,
          availableScenarios,
      }) => {
    const [initialStateDone, setInitialStateDone] = useState<boolean>(false);

    useEffect(() => {
        if (!initialStateDone) {
            setSelectedScenario(availableScenarios[0].title);
            setInitialStateDone(true);
        }
    }, [initialStateDone, setSelectedScenario, availableScenarios]);

    return (
        <div className="bg-headerGray rounded-xl p-2 lg:flex overflow-hidden items-center ml-4 lg:sticky top-[110px]">
            <FilterSelect selectedScenario={selectedScenario} handleScenarioChange={handleScenarioChange}
                          availableScenarios={availableScenarios}/>
        </div>
    );
}

export default FilterHeader;
