import React, {Dispatch, useState} from "react";
import TypeRefLabel from "./TypeRefLabel";
import NoteToolTip from "./NoteToolTip";
import {Notes} from "../types/Notes";
import ModalAdvice from "./ModalAdvice";
import {ButtonType} from "../types/ButtonType";
import Alert from "./Alert";
import {mutateArtifactType} from "../api/apollo/mutations/artifactTypeMutation";
import {mutateArtifact} from "../api/apollo/mutations/artifactMutation";
import {mutateUserStories} from "../api/apollo/mutations/userStoriesMutation";
import {ArtifactTypes} from "../types/ArtifactTypes";
import {UserStories} from "../types/UserStories";
import {faGrip} from "@fortawesome/free-solid-svg-icons/faGrip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Markdown from "marked-react";

const Card: React.FC<{
    repositoryID: string,
    artifact: any,
    artifactTypes: ArtifactTypes[],
    userStories: UserStories[],
    goalMarket: string | undefined,
    loadingResponse: boolean,
    setToAnalyseText: Dispatch<string>,
    adviceText: string,
    notes: Notes[],
    refetch: () => void,
}> = ({
          repositoryID,
          artifact,
          artifactTypes,
          userStories,
          goalMarket,
          loadingResponse,
          setToAnalyseText,
          adviceText,
          notes,
          refetch
      }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [alertActive, setAlertActive] = useState<boolean>(false);
    const [currentButtonType, setCurrentButtonType] = useState<ButtonType>(ButtonType.DEFAULT);
    const [adviceTitle, setAdviceTitle] = useState('Sustainable Advisory');

    const handleModalOpen = (artifact: any) => {
        setModalOpen(true);
        setToAnalyseText(`Analyse this idea
        \nArtifact title: ${artifact.line} 
        \nArtifact: ${artifact.description} 
        \nScenarioStep: ${artifact.scenarioStep?.line} 
        \nUserNeed: ${artifact.userStory?.userNeed}
        \nGoal market: ${goalMarket}`);
    }
    const deleteAdvice = () => {
        setCurrentButtonType(ButtonType.DELETE);
        setAlertActive(true);
        setModalOpen(false);
    }

    const reAnalyzeText = () => {
        if (!loadingResponse) {
            setToAnalyseText(`Analyse this idea
            \nArtifact title: ${artifact.line} 
            \nArtifact: ${artifact.description} 
            \nScenario step: ${artifact.scenarioStep?.line} 
            \nUserNeed: ${artifact.userStory?.userNeed}
            \nGoal market: ${goalMarket}`);
        }
    }

    const saveAdvice = async (userStoryGuid: string, AIAdvice: string) => {
        const mutateArtifactTypeId = await mutateArtifactType(artifactTypes, repositoryID);
        const mutateArtifactId = await mutateArtifact(adviceTitle, AIAdvice, mutateArtifactTypeId, repositoryID);
        if (mutateArtifactId) {
            await mutateUserStories(userStories, mutateArtifactId, userStoryGuid, repositoryID);
        }
        setCurrentButtonType(ButtonType.SAVE);
        setAlertActive(true);
        setModalOpen(false);
        refetch();
    }

    const handleSaveAdvice = async () => {
        await saveAdvice(artifact.userStory._guid, adviceText);
    }

    return (
        <div className="relative block overflow-hidden rounded-lg p-4 lg:p-6 bg-white shadow-md">
            <span className="absolute flex inset-y-0 right-0 w-8 justify-center"
                  style={{backgroundColor: artifact.typeRef?.color}}>
                <FontAwesomeIcon className="text-white mt-4" icon={faGrip}/>
            </span>
            <div className="lg:flex lg:justify-between lg:gap-4 mb-1 mr-8">
                <h3 className="text-lg font-bold lg:text-xl">
                    <NoteToolTip notes={notes} text={artifact.line}/>
                </h3>
            </div>

            <TypeRefLabel label={artifact.typeRef?.name}
                          customColor={artifact.typeRef?.color}/>

            <div className="mt-4 text-pretty text-sm mr-8">
                <Markdown>
                    {artifact.description}
                </Markdown>
            </div>


            <div className="mt-6 flex flex-col-reverse text-xs mr-8">
                <NoteToolTip notes={notes} text={artifact.userStory.userNeed}/>
                <dt className="text-base font-light">User Need</dt>
            </div>

            {artifact.typeRef?.name !== 'Sustainability Advisory' && (
                <div className="mt-4">
                    <button
                        className="group inline-block rounded-full bg-gradient-to-r from-green-400 to-blue-500 p-[2px]"
                        onClick={() => handleModalOpen(artifact)}
                        disabled={loadingResponse}
                    >
                <span
                    className="block rounded-full bg-white px-8 py-3 text-sm font-medium group-hover:bg-transparent">
                    Analyze
                </span>
                    </button>
                </div>
            )}
            {modalOpen && (
                <ModalAdvice artifact={artifact} notes={notes} closeModal={setModalOpen}
                             loadingResponse={loadingResponse} adviceText={adviceText} reAnalyzeText={reAnalyzeText}
                             deleteAdvice={deleteAdvice} saveAdvice={handleSaveAdvice}
                             adviceTitle={adviceTitle} setAdviceTitle={setAdviceTitle}/>
            )}
            {alertActive &&
                <Alert alertActive={alertActive} setAlertActive={setAlertActive} buttonType={currentButtonType}/>
            }
        </div>
    )
}

export default Card;