import React, {useState} from 'react';
import Logo from "../assets/Logo.LeanScopeWhite.svg";
import SessionTime from "./SessionTime";
import PersonaInformationMobile from "./PersonaInformationMobile";

const MenuBar: React.FC<{ combinedData?: any | null, repositoryID: string }> = ({combinedData, repositoryID}) => {
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const handleOpenMenu = () => {
        setOpenMenu(!openMenu);
    }

    return (
        <div className="bg-menu px-4 mb-4 flex justify-between items-center py-2 pb-1 sticky top-0 z-10">
            <a href="https://app.leanscope.io/#/home">
                <img
                    src={Logo}
                    alt="LeanScope Logo"
                    className="cursor-pointer w-12 h-12"
                />
            </a>
            {combinedData && (
                <div className="relative">
                    <div className="flex items-center">
                        <SessionTime/>
                        <img
                            className="block h-10 w-10 rounded-full cursor-pointer lg:hidden"
                            alt="KeyPersona"
                            src={`${process.env.REACT_APP_PICTURE_URI}/${repositoryID}/${combinedData[0]?.persona?.image?.imagePath}.png`}
                            onClick={handleOpenMenu}
                        />
                    </div>
                    {openMenu && (
                        <div
                            className="absolute right-0 top-12 z-10 mt-2 w-80 rounded-md border border-gray-100 bg-white shadow-lg"
                            role="menu"
                        >
                            <div
                                className="relative block overflow-hidden rounded-lg pb-4 lg:p-8 bg-white shadow-md">
                                <span className="absolute inset-x-0 bottom-0 h-2 bg-menu"/>
                                <PersonaInformationMobile combinedData={combinedData}
                                                          handleOpenMenu={handleOpenMenu}/>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MenuBar;
