import * as React from "react";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import NoteToolTip from "./NoteToolTip";
import {Notes} from "../types/Notes";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";

const ScenarioStepFilter: React.FC<{
    selectedScenarioStep: string,
    scenarioSteps: string[],
    findVisuals: () => string,
    setSelectedScenarioStep: Dispatch<SetStateAction<string>>
    notes: Notes[],
    initialState: boolean
    setInitialState: (bool: boolean) => void
}> =
    ({
         selectedScenarioStep,
         scenarioSteps,
         findVisuals,
         setSelectedScenarioStep,
         notes,
         initialState,
        setInitialState
     }) => {
        const [currentIndex, setCurrentIndex] = useState(0);
        const [showText, setShowText] = useState(true);

        useEffect(() => {
            if (initialState) {
                setSelectedScenarioStep(scenarioSteps[currentIndex]);
                setInitialState(false);
            }
        }, [currentIndex, setSelectedScenarioStep, scenarioSteps, initialState, setInitialState]);

        const handlePrevious = () => {
            const newIndex = currentIndex > 0 ? currentIndex - 1 : scenarioSteps.length - 1;
            setCurrentIndex(newIndex);
            setSelectedScenarioStep(scenarioSteps[newIndex]);
        };

        const handleNext = () => {
            const newIndex = currentIndex < scenarioSteps.length - 1 ? currentIndex + 1 : 0;
            setCurrentIndex(newIndex);
            setSelectedScenarioStep(scenarioSteps[newIndex]);
        };



        return (
            <div className="lg:w-2/3 p-4 bg-white rounded-xl space-y-4">
                <h3 className="text-lg text-center font-bold lg-4text-xl">Szenario Schritt</h3>
                <div className="flex items-center justify-between mb-4 lg:min-h-24">
                    <button
                        className="px-3 py-1 bg-gray-200 rounded-xl flex-shrink-0 mr-4"
                        onClick={handlePrevious}
                    >
                        <FontAwesomeIcon icon={faChevronLeft}/> Vorheriger
                    </button>

                    <img
                        className="h-24 w-24 rounded-full lg:inline-block hidden"
                        alt="KeyPersona"
                        src={`${process.env.REACT_APP_VISUALS_URI}${findVisuals()}`}
                    />

                    <button
                        className="px-3 py-1 bg-gray-200 rounded-xl flex-shrink-0 ml-4"
                        onClick={handleNext}
                    >
                        Nächster <FontAwesomeIcon icon={faChevronRight}/>
                    </button>
                </div>
                <div className="flex items-center justify-center" onClick={() => setShowText(!showText)}>
                    <img
                        className="h-24 w-24 rounded-full lg:hidden inline-block"
                        alt="KeyPersona"
                        src={`${process.env.REACT_APP_VISUALS_URI}${findVisuals()}`}
                    />
                    {!showText ?
                        <div className="text-menu lg:hidden">
                            <FontAwesomeIcon icon={faChevronDown}/>
                        </div> :
                        <div className="text-menu lg:hidden">
                            <FontAwesomeIcon icon={faChevronUp}/>
                        </div>
                    }
                </div>

                {showText && (
                    <div className="text-sm block lg:hidden">
                        <NoteToolTip notes={notes} text={selectedScenarioStep}/>
                    </div>
                )}

                <div className="text-sm hidden lg:block">
                    <NoteToolTip notes={notes} text={selectedScenarioStep}/>
                </div>
            </div>
        )
    }

export default ScenarioStepFilter;

