import React from "react";
import Header from "./Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

const PersonaInformationMobile: React.FC<{ combinedData: any, handleOpenMenu?: () => void}> =
    ({
         combinedData,
         handleOpenMenu
     }) => {
        if (combinedData) {
            const dataPersona = combinedData[0]?.persona;
            return (
                <div className="lg:w-1/3 ml-4 bg-white rounded-xl flex-shrink-0 lg:hidden block space-y-4 mr-4 mt-2">
                    <div className="text-sm">
                        <Header text={dataPersona?.name}/>
                        <FontAwesomeIcon icon={faClose}
                                         className="absolute top-0 right-0 mt-2 mr-2 cursor-pointer size-6 lg:hidden block"
                                         onClick={handleOpenMenu}/>
                        {combinedData[0]?.jobDescription}
                    </div>
                    <div className="text-sm">
                        <Header text="PERSONAZIEL"/>
                        {combinedData[0]?.headScenario?.personaGoal}
                    </div>
                    <div className="text-sm">
                        <Header text="INTERAKTION MIT ANDEREN"/>
                        {combinedData[0]?.headScenario?.interactions}
                    </div>
                    <div className="text-sm">
                        <Header text="UMGEBUNG"/>
                        {combinedData[0]?.headScenario?.environment}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

export default PersonaInformationMobile;