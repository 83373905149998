import React from "react";
import NoteToolTip from "./NoteToolTip";
import TypeRefLabel from "./TypeRefLabel";
import {Notes} from "../types/Notes";
import {faClose, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Markdown from "marked-react";

const ModalAdvice: React.FC<{
    artifact: any,
    notes: Notes[],
    closeModal: (active: boolean) => void;
    loadingResponse: boolean,
    adviceText: string,
    reAnalyzeText: () => void;
    deleteAdvice: () => void
    saveAdvice: () => void;
    adviceTitle: string,
    setAdviceTitle: (value: React.SetStateAction<string>) => void,
}> = ({
          artifact,
          notes,
          closeModal,
          loadingResponse,
          adviceText,
          reAnalyzeText,
          deleteAdvice,
          saveAdvice,
          adviceTitle,
          setAdviceTitle,
      }) => {

    const handleTitleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setAdviceTitle(event.target.value);
    };

    return (
        <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-10 p-4 overflow-y-auto">
            <div className="my-auto bg-white shadow-lg rounded-lg p-6 relative">
                <div className="absolute top-0 right-0 m-4 transition hover:text-gray-600"
                     onClick={() => closeModal(false)}>
                    <FontAwesomeIcon
                        className={`text-2xl ${loadingResponse ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                        icon={faClose}
                    />
                </div>
                <div className="relative block overflow-hidden rounded-lg p-4 lg:p-8 bg-white shadow-md mt-4">
                    <span className="absolute inset-x-0 bottom-0 h-2 bg-menu"/>
                    <div className="sm:flex sm:justify-between sm:gap-4 mb-1">
                        <div>
                            <h3 className="text-lg font-bold text-gray-900 sm:text-xl">
                                <NoteToolTip notes={notes} text={artifact.line}/>
                            </h3>
                        </div>
                    </div>
                    <TypeRefLabel label={artifact.typeRef?.name} customColor={artifact.typeRef?.color}/>
                    <div className="mt-4">
                        <div className="text-pretty text-sm">
                            <NoteToolTip notes={notes} text={artifact.description}/>
                        </div>
                    </div>
                    <dl className="mt-6">
                        <div className="flex flex-col-reverse text-xs">
                            <NoteToolTip notes={notes} text={artifact.userStory.userNeed}/>
                            <dt className="text-base font-light">User Need</dt>
                        </div>
                    </dl>
                    <dl className="mt-6">
                        <div className="flex flex-col-reverse text-xs">
                            <NoteToolTip notes={notes} text={artifact.scenarioStep?.line}/>
                            <dt className="text-base font-light">Szenario Schritt</dt>
                        </div>
                    </dl>
                </div>
                <div className="relative block overflow-hidden rounded-lg p-4 lg:p-8 bg-white shadow-md mt-4">
                    <span className="absolute inset-x-0 bottom-0 h-2 bg-green-800"/>
                    <div className="flex justify-between sm:gap-4 mb-1">
                        <div className="w-full">
                            <div className="flex items-center space-x-4">
                                <input
                                    type="text"
                                    value={adviceTitle}
                                    onChange={handleTitleChange}
                                    className={`text-lg font-bold text-gray-900 sm:text-xl border border-gray-300 rounded-md px-2 py-1 w-full lg:w-1/3 ${
                                        loadingResponse ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                    disabled={loadingResponse}
                                />
                                <FontAwesomeIcon icon={faPenToSquare}/>
                            </div>
                            {loadingResponse && (
                                <div className="mt-4">Loading ...</div>
                            )}
                            {!loadingResponse && (
                                <div className="text-sm mt-4">
                                    <Markdown>{adviceText}</Markdown>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden bg-white mt-4 lg:flex space-y-2 lg:space-y-0 justify-between">
                    <div className="flex justify-center">
                        <button
                            className={`group inline-block rounded-full bg-gradient-to-r from-green-400 to-blue-500 p-[2px] w-full lg:w-fit ${
                                loadingResponse ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            onClick={reAnalyzeText}
                            disabled={loadingResponse}
                        >
                            <span
                                className="block rounded-full bg-white px-4 py-3 text-sm font-medium group-hover:bg-transparent">
                                New Advice
                            </span>
                        </button>
                    </div>
                    <div className="space-x-2 sm:space-x-2 flex justify-center">
                        <button
                            className={`group inline-block rounded-full bg-gradient-to-r from-red-400 to-red-600 p-[2px] w-full lg:w-fit ${
                                loadingResponse ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            onClick={deleteAdvice}
                            disabled={loadingResponse}
                        >
                            <span
                                className="block rounded-full bg-white px-4 py-3 text-sm font-medium group-hover:bg-transparent">
                                Delete Advice
                            </span>
                        </button>
                        <button
                            className={`group inline-block rounded-full bg-gradient-to-r from-green-400 to-blue-500 p-[2px] w-full lg:w-fit ${
                                loadingResponse ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            onClick={saveAdvice}
                            disabled={loadingResponse}
                        >
                            <span
                                className="block rounded-full bg-white px-4 py-3 text-sm font-medium group-hover:bg-transparent">
                                Save Advice
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAdvice;
