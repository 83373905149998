import React, {useState} from 'react';
import {Artifacts} from "./types/Artifacts";
import {BusinessGoals} from "./types/BusinessGoals";
import {Scenarios} from "./types/Scenarios";
import ArtifactCards from "./components/ArtifactCards";
import PersonaInformation from "./components/PersonaInformation";
import {Notes} from "./types/Notes";
import {combineDataHelper} from "./api/apollo/queries/combineData";
import FilterHeader from "./components/FilterHeader";
import ScenarioStepFilter from "./components/ScenarioStepFilter";
import MenuBar from "./components/MenuBar";

const App: React.FC<{ dataUXM: any, repositoryID: string, refetch: () => void }> = ({dataUXM, repositoryID, refetch}) => {
    const [selectedScenario, setSelectedScenario] = useState<string | null>(null);
    const [selectedScenarioStep, setSelectedScenarioStep] = useState<string | "">("");
    const [initialState, setInitialState] = useState<boolean>(true);

    const businessGoals: BusinessGoals[] = dataUXM.uxmData.businessGoals;
    const notes: Notes[] = dataUXM.uxmData.notes;

    const combineData = combineDataHelper(dataUXM, selectedScenario, selectedScenarioStep);

    const availableScenarios = dataUXM.uxmData.scenarios.filter((scenario: Scenarios) => {
        return dataUXM.uxmData.artifacts.some((artifact: Artifacts) => {
            const userStory = dataUXM.uxmData.userStories.find(
                (story: any) => story.artifacts.some((art: any) => art.artifact === artifact._guid)
            );

            const headScenario = dataUXM.uxmData.scenarios.find(
                (sc: Scenarios) => sc._guid === userStory?.originScenarioRef
            );

            return headScenario?.title === scenario.title;
        });
    });

    const availableScenarioSteps = availableScenarios
        .filter((scenario: Scenarios) => !selectedScenario || scenario.title === selectedScenario)
        .map((scenario: any) => {
            return scenario.scenario?.map((step: any) => {
                return {
                    line: step.line,
                    visuals: step.visuals?.[0]
                };
            });
        })
        .flat();

    const availableScenarioStepsWithArtifacts = availableScenarioSteps.map((step: any) => ({
        line: step.line,
        visuals: step.visuals
    })).filter((step: any) => {
        return dataUXM.uxmData.artifacts.some((artifact: Artifacts) => {
            const userStory = dataUXM.uxmData.userStories.find(
                (story: any) => story.artifacts.some((art: any) => art.artifact === artifact._guid)
            );

            const headScenario = dataUXM.uxmData.scenarios.find(
                (sc: Scenarios) => sc._guid === userStory?.originScenarioRef
            );

            const scenarioStep = headScenario?.scenario.find(
                (scenario: any) => scenario._guid === userStory?.originScenarioStepRef
            );

            return scenarioStep?.line === step.line;
        });
    });

    const arrayAvailableScenarioSteps = availableScenarioStepsWithArtifacts.flatMap((scenario: any) => {
        return scenario.line;
    });

    const findVisuals = (): string => {
        if (selectedScenarioStep === null) {
            return "";
        }

        const visuals = dataUXM.uxmData.scenarios.flatMap((scenario: { scenario: any; }) => scenario.scenario)
            .find((scenario: { line: string; }) => scenario.line === selectedScenarioStep)?.visuals?.[0];

        return visuals?.substring(1) || "";
    }

    const handleScenarioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedScenario(event.target.value);
        setSelectedScenarioStep("");
        setInitialState(true);
    };

    return (
        <>
            <MenuBar combinedData={combineData()} repositoryID={repositoryID}/>
            <div className="lg:flex lg:space-x-4">
                <div className="lg:w-3/5 lg:mr-0 mr-4 lg:mb-0 mb-4 space-y-4 shrink-0">
                    <FilterHeader selectedScenario={selectedScenario}
                                  handleScenarioChange={handleScenarioChange}
                                  availableScenarios={availableScenarios}
                                  setSelectedScenario={setSelectedScenario}/>
                    <div className="lg:flex lg:space-x-4 lg:ml-0 ml-4 sticky top-52">
                        <PersonaInformation combinedData={combineData()} repositoryID={repositoryID}/>
                        <ScenarioStepFilter selectedScenarioStep={selectedScenarioStep}
                                            scenarioSteps={arrayAvailableScenarioSteps}
                                            findVisuals={findVisuals}
                                            setSelectedScenarioStep={setSelectedScenarioStep}
                                            notes={notes} initialState={initialState} setInitialState={setInitialState}/>
                    </div>

                </div>
                <ArtifactCards repositoryID={repositoryID} combinedData={combineData()}
                               artifactTypes={dataUXM.uxmData.artifactTypes}
                               userStories={dataUXM.uxmData.userStories} notes={notes}
                               goalMarket={businessGoals[0].goalMarket} refetch={refetch}/>
            </div>

        </>
    );
};

export default App;
