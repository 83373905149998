import {getSessionToken} from "../../helper/getUrlParameter";

const OPENAI_URI = process.env.REACT_APP_OPENAI_URI;

const openAI_API = async (inputData: string, teamRepositoryId: string) => {
    const userToken = getSessionToken();

    if (!OPENAI_URI) {
        console.warn('OPENAI_URI is not defined. Unable to make API request.');
        return null;
    }

    const requestData = {
        teamRepositoryId: teamRepositoryId,
        message: inputData
    };

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'userToken': userToken || '',
        },
        body: JSON.stringify(requestData),
    };

    try {
        const response = await fetch(OPENAI_URI, requestOptions);
        if (!response.ok) {
            console.error(`Network response was not ok. Status: ${response.status}`);
            return null;
        }

        const contentType = response.headers.get('content-type');

        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        } else {
            return await response.text();
        }
    } catch (error) {
        console.error('Fetch error:', error);
        return null;
    }
};

export default openAI_API;
