import React, {useEffect, useState} from 'react';
import {Notes} from "../types/Notes";
import Card from "./Card";
import {ArtifactTypes} from "../types/ArtifactTypes";
import {UserStories} from "../types/UserStories";
import Sources from "./Sources";
import openAI_API from "../api/openai/openai-api";

const ArtifactCards: React.FC<{
    repositoryID: string,
    combinedData: any,
    artifactTypes: ArtifactTypes[],
    userStories: UserStories[],
    goalMarket: string | undefined,
    notes: Notes[],
    refetch: () => void,
}> = ({
          repositoryID, combinedData, artifactTypes, userStories, goalMarket, notes, refetch
      }) => {
    const [toAnalyseText, setToAnalyseText] = useState<string>('');
    const [adviceText, setAdviceText] = useState<string>('');
    const [loadingResponse, setLoadingResponse] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSendMessage = async (message: string) => {
        setLoadingResponse(true);

        const responseText = await openAI_API(message, repositoryID);

        if (responseText) {
            setAdviceText(responseText);
        } else {
            console.error('Failed to receive response from API.');
            setAdviceText('Failed to receive response from API.')
        }

        setLoadingResponse(false);
    };

    useEffect(() => {
        if (toAnalyseText !== '') {
            handleSendMessage(toAnalyseText).then();
            setToAnalyseText('');
        }
    }, [handleSendMessage, toAnalyseText]);

    if (combinedData) {
        return (
            <div className="lg:w-3/5 overflow-hidden w-full lg:pr-4">
                <div className={`space-y-4 lg:mx-0 mx-4 ${loadingResponse ? 'overflow-hidden' : ''}`}>
                    {combinedData.map((combinedArtifact: any, index: number) => (
                        <Card repositoryID={repositoryID} artifact={combinedArtifact} artifactTypes={artifactTypes}
                              userStories={userStories}
                              goalMarket={goalMarket}
                              loadingResponse={loadingResponse}
                              setToAnalyseText={setToAnalyseText}
                              adviceText={adviceText}
                              notes={notes} key={index} refetch={refetch}
                        />
                    ))}

                </div>
                <Sources goalMarket={goalMarket}/>
            </div>
        );
    }
    return null;
};

export default ArtifactCards;