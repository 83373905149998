import React from "react";
import Header from "./Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

const PersonaInformation: React.FC<{ combinedData: any, handleOpenMenu?: () => void, repositoryID: string }> =
    ({
         combinedData,
         handleOpenMenu,
         repositoryID
     }) => {
        if (combinedData) {
            const dataPersona = combinedData[0]?.persona;
            return (
                    <div className="lg:w-1/3 ml-4 bg-white rounded-xl flex-shrink-0 lg:block hidden space-y-4 p-4">
                        <div className="flex items-center justify-center lg:mt-4 mt-0">
                            <img
                                className="h-24 w-24 rounded-full lg:inline-block hidden"
                                alt="KeyPersona"
                                src={`${process.env.REACT_APP_PICTURE_URI}/${repositoryID}/${dataPersona?.image?.imagePath}.png`}
                            />
                        </div>
                        <div className="text-sm">
                            <Header text={dataPersona?.name}/>
                            <FontAwesomeIcon icon={faClose}
                                             className="absolute top-0 right-0 mt-2 mr-2 cursor-pointer size-6 lg:hidden block"
                                             onClick={handleOpenMenu}/>
                            {combinedData[0]?.jobDescription}
                        </div>
                        <div className="text-sm">
                            <Header text="PERSONAZIEL"/>
                            {combinedData[0]?.headScenario?.personaGoal}
                        </div>
                        <div className="text-sm">
                            <Header text="INTERAKTION MIT ANDEREN"/>
                            {combinedData[0]?.headScenario?.interactions}
                        </div>
                        <div className="text-sm">
                            <Header text="UMGEBUNG"/>
                            {combinedData[0]?.headScenario?.environment}
                        </div>
                    </div>
            );
        } else {
            return null;
        }
    };

export default PersonaInformation;