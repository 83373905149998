import React from "react";
import {Scenarios} from "../types/Scenarios";

const FilterSelect: React.FC<{
    selectedScenario: string | null,
    handleScenarioChange: any,
    availableScenarios: any,
}> = ({selectedScenario, handleScenarioChange, availableScenarios}) => {
    return (
        <div className="w-full">
            <div
                className="block text-sm ml-1 text-white font-bold"> Szenario
            </div>
            <select
                id={"Scenario"}
                name={"Scenario"}
                value={selectedScenario || ""}
                onChange={handleScenarioChange}
                className="w-full rounded-lg border-gray-300 bg-headerGray hover:bg-gray-600 text-white lg:text-lg h-8 focus-visible:bg-headerGray outline-none mb-2"
            >
                {availableScenarios.map((scenario: Scenarios) => (
                    <option key={scenario._guid} value={scenario.title}>
                        {scenario.title}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default FilterSelect;
