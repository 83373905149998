import {gql, useQuery} from "@apollo/client";
import {uxmQuery} from "../api/apollo/queries/uxmQuery";
import React from "react";
import App from "../App";

const DataQueryCheck: React.FC<{ repositoryID: string }> = ({repositoryID}) => {
    const GET_Artifacts = gql(uxmQuery(repositoryID));
    const {loading, error, data, refetch} = useQuery(GET_Artifacts);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <App dataUXM={data} repositoryID={repositoryID} refetch={refetch}/>
        </>
    )
}

export default DataQueryCheck;
