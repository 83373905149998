export const uxmQuery = (repositoryId: string) => `
  query {
    uxmData(repositoryId: ${repositoryId}) {
      artifacts {
        _guid
        line
        typeRef
        description
      }
      artifactTypes {
        _guid
        name
        color
      }
      scenarios {
        _guid
        title
        scope
        scenario {
            _guid
            line
            visuals
        }
        personaGoal
        preconditions
        interactions
        environment
        primaryActorRef
      }
      userStories {
        _guid
        artifacts {
            artifact
        }
        originScenarioRef
        originScenarioStepRef
        title
        userNeed
      }
      businessGoals {
        _guid
        goalMarket
      }
      personas {
        _guid
        name
        sociodemographicInfo {
            key
            description
        }
        image {
            imagePath
        }
      }
      notes {
        _guid
        text
      }
    }
  }
`;