import {Artifacts} from "../../../types/Artifacts";
import {Scenarios} from "../../../types/Scenarios";
import {ArtifactTypes} from "../../../types/ArtifactTypes";

export function combineDataHelper(data: {
    uxmData: { artifacts: any; userStories: any[]; scenarios: any[]; artifactTypes: any[]; personas: any[]; };
}, selectedScenario: string | null, selectedScenarioStep: string | null) {
    return () => {
        if (data && data.uxmData) {
            let filteredArtifacts = data.uxmData.artifacts;

            filteredArtifacts = filteredArtifacts.filter((artifact: Artifacts) => {
                const userStory = data.uxmData.userStories.find(
                    (story: any) => story.artifacts.some((art: any) => art.artifact === artifact._guid)
                );
                return userStory?.title !== undefined;
            });

            // Apply scenario filter if a scenario is selected
            if (selectedScenario) {
                filteredArtifacts = filteredArtifacts.filter((artifact: Artifacts) => {
                    const userStory = data.uxmData.userStories.find(
                        (story: any) => story.artifacts.some((art: any) => art.artifact === artifact._guid)
                    );

                    const headScenario = data.uxmData.scenarios.find(
                        (sc: Scenarios) => sc._guid === userStory?.originScenarioRef
                    );

                    return headScenario?.title === selectedScenario;
                });
            }

            if (selectedScenarioStep) {
                filteredArtifacts = filteredArtifacts.filter((artifact: Artifacts) => {
                    const userStory = data.uxmData.userStories.find(
                        (story: any) => story.artifacts.some((art: any) => art.artifact === artifact._guid)
                    );

                    const headScenario = data.uxmData.scenarios.find(
                        (sc: Scenarios) => sc._guid === userStory?.originScenarioRef
                    );

                    const scenarioStep = headScenario?.scenario.find(
                        (scenario: any) => scenario._guid === userStory?.originScenarioStepRef
                    );

                    // Include only artifacts where scenarioStep matches the selectedScenarioStep
                    return scenarioStep?.line === selectedScenarioStep;
                });
            }

            return filteredArtifacts.map((artifact: Artifacts) => {
                const userStory = data.uxmData.userStories.find(
                    (story: any) => story.artifacts.some((art: any) => art.artifact === artifact._guid)
                );

                const typeRef = data.uxmData.artifactTypes.find(
                    (type: ArtifactTypes) => type._guid === artifact.typeRef
                );

                const headScenario = data.uxmData.scenarios.find(
                    (sc: Scenarios) => sc._guid === userStory?.originScenarioRef
                );

                const scenarioStep = headScenario?.scenario.find(
                    (scenario: any) => scenario._guid === userStory?.originScenarioStepRef
                );

                const persona = data.uxmData.personas.find(
                    (p: any) => p._guid === headScenario?.primaryActorRef
                );

                const jobDescription = persona?.sociodemographicInfo.find(
                    (info: any) => info.key === "Profession"
                )?.description;

                return {
                    ...artifact,
                    typeRef,
                    userStory,
                    headScenario,
                    scenarioStep,
                    persona,
                    jobDescription,
                };
            }).sort((a: any, b: any) => {
                const titleA = a.userStory?.title || "";
                const titleB = b.userStory?.title || "";
                return titleA.localeCompare(titleB);
            });
        }
        return [];
    };
}