import React from "react";

const Sources: React.FC<{ goalMarket: string | undefined }> = ({goalMarket}) => {
    return (
        <div className="my-4 text-sm mx-4">
            <div>Ideas get analysed against <a className="text-blue-400 hover:underline"
                                               href="https://commission.europa.eu/">EU
                Laws</a>, <a className="text-blue-400 hover:underline" href="https://sdgs.un.org/goals">SDG's of
                UN</a> and
                other sources for technical design decisions on the internet
                (<a className="text-blue-400 hover:underline" href="https://gr491.isit-europe.org/en/">Handbook of
                    sustainable
                    design</a>
                ,<a className="text-blue-400 hover:underline" href="https://learn.greensoftware.foundation/"
                    style={{margin: 5}}>GreenSoftware</a>
                and <a className="text-blue-400 hover:underline" href="https://nachhaltiges-webdesign.jetzt">Sustainable
                    web design</a>).
                Furthermore they get analysed against your chosen goal market {goalMarket}</div>
        </div>
    );
}

export default Sources;