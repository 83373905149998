import {v4 as uuid} from 'uuid';
import {GraphQLClient} from "../graph-ql.client";
import {gql} from "@apollo/client";
import {ArtifactsMutation} from "../../../types/ArtifactsMutation";

const artifactMutation = `
mutation CreateArtifact($gitLabProjectId: Int!, $sessionGuid: String!, $randomGuid: String!, $title: String!, $description: String!, $timeStamp: Float!, $typeRef: String!) {
  repository(
    gitLabProjectId: $gitLabProjectId
    sessionGuid: $sessionGuid
    uxmDataInput: {
      artifacts: [
        {
          _guid: $randomGuid
          line: $title
          description: $description
          typeRef: $typeRef
          timeStamp: $timeStamp
          action: CREATE
          url: ""
        }
      ]
    }
  ) {
    artifactsMutated {
      _guid
    }
  }
}
`;

export const mutateArtifact = async (adviceTitle: string, AIAdvice: string, TypeRef: any, repositoryId: string) => {
    try {
        const randomGuid = uuid();

        const artifactResponse = await GraphQLClient.mutate<ArtifactsMutation>({
            mutation: gql(artifactMutation),
            variables: {
                gitLabProjectId: Number(repositoryId),
                sessionGuid: "52f823af-40b8-401c-a0b9-6a06d1a5af19",
                randomGuid: randomGuid,
                title: adviceTitle,
                description: AIAdvice,
                typeRef: TypeRef,
                timeStamp: new Date().getTime(),
            },
        });

        return artifactResponse.data?.repository?.artifactsMutated[0]._guid;
    } catch (error) {
        console.error(error);
        return "";
    }
};
