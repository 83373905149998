import {GraphQLClient} from "../graph-ql.client";
import {ArtifactsMutation} from "../../../types/ArtifactsMutation";
import {gql} from "@apollo/client";

const userStoriesMutation = `
    mutation UpdateUserStory($gitLabProjectId: Int!, $sessionGuid: String!, $userStoryGuid: String!, $artifacts: [ArtifactRefInput!]!) {
        repository(
            gitLabProjectId: $gitLabProjectId
            sessionGuid: $sessionGuid
            uxmDataInput: {
                userStories: {
                    _guid: $userStoryGuid
                    artifacts: $artifacts
                    action: UPDATE
                }
            }
        ) {
        userStoriesMutated {
            _guid
        }
    }
}`;

export const mutateUserStories = async (userStories: any[], newArtifactMutation: string, userStoryGuid: string, repositoryId: string) => {
    try {
        const targetUserStory = userStories.find(story => story._guid === userStoryGuid);

        if (targetUserStory) {
            const artifactsList = targetUserStory.artifacts?.map((artifacts: any) => ({artifact: artifacts?.artifact})) || [];
            artifactsList.push({ artifact: newArtifactMutation });

            await GraphQLClient.mutate<ArtifactsMutation>({
                mutation: gql(userStoriesMutation),
                variables: {
                    gitLabProjectId: Number(repositoryId),
                    sessionGuid: "52f823af-40b8-401c-a0b9-6a06d1a5af19",
                    userStoryGuid: userStoryGuid,
                    artifacts: artifactsList,
                },
            });

            return "success";
        } else {
            return "error";
        }
    } catch (error) {
        return "error";
    }
};
